jQuery(function ($) {
  'use strict';

  // calc order
  $('.menu-calc-box select')
    .change(function () {
      var t = $(this),
        box = t.parents('.menu-calc-box'),
        resultTotal = 0,
        result = t.data('current-price') * t.val();
      box.find('.calc-result').text(number_format(result)).data('total', result);
      $('.menu-calc-box').each(function () {
        var result = $(this).find('.calc-result');
        if (result.length) {
          resultTotal += parseInt(result.data('total'));
        }
      });
      $('.calc-result-total span').text(number_format(resultTotal));
    })
    .change();

  function number_format(num) {
    return ('' + num).replace(/([0-9])(?=([0-9]{3})+$)/g, '$1,');
  }

  $('#purchase-signin-form input').on('keypress', function (ev) {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      $('#purchase-signin-button').click();
    }
  });

  /**
   * h2z dakuten
   */
  function h2z_dakuten(val) {
    var map = {
      ｶﾞ: 'ガ',
      ｷﾞ: 'ギ',
      ｸﾞ: 'グ',
      ｹﾞ: 'ゲ',
      ｺﾞ: 'ゴ',
      ｻﾞ: 'ザ',
      ｼﾞ: 'ジ',
      ｽﾞ: 'ズ',
      ｾﾞ: 'ゼ',
      ｿﾞ: 'ゾ',
      ﾀﾞ: 'ダ',
      ﾁﾞ: 'ヂ',
      ﾂﾞ: 'ヅ',
      ﾃﾞ: 'デ',
      ﾄﾞ: 'ド',
      ﾊﾞ: 'バ',
      ﾋﾞ: 'ビ',
      ﾌﾞ: 'ブ',
      ﾍﾞ: 'ベ',
      ﾎﾞ: 'ボ',
      ﾊﾟ: 'パ',
      ﾋﾟ: 'ピ',
      ﾌﾟ: 'プ',
      ﾍﾟ: 'ペ',
      ﾎﾟ: 'ポ',
      ｳﾞ: 'ヴ',
      ﾜﾞ: 'ヷ',
      ｦﾞ: 'ヺ',
    };

    var reg = new RegExp('(' + Object.keys(map).join('|') + ')', 'g');
    return val.replace(reg, function (match) {
      return map[match];
    });
  }

  // add error class
  function addErrorClass() {
    $('.error-message:visible:not(:empty):not(:disabled)').closest('tr,li').addClass('has-errors');
  }
  addErrorClass();

  // jQuery Validate Plugin
  $('form.order-form').each(function () {
    var form = $(this),
      validator,
      phoneValidates = [/^(?!\d*00\d*$)0\d{1,5}$/, /^\d{1,6}$/, /^\d{1,6}$/],
      reKana = /^[ァ-ロワヲンヴぁ-ろわをんー・゛゜｡-ﾟ]+$/,
      rules = {
        'user[family_name]': {
          required: [true, 'お名前(姓)'],
        },
        'user[first_name]': {
          required: [true, 'お名前(名)'],
        },
        'user[family_kana]': {
          required: [true, 'フリガナ(セイ)'],
          regex: [reKana, 'フリガナ(セイ)'],
        },
        'user[first_kana]': {
          required: [true, 'フリガナ(メイ)'],
          regex: [reKana, 'フリガナ(メイ)'],
        },
        'user[phone1]': {
          required: [true, '電話番号'],
          regex_each: [['#user_phone1', '#user_phone2', '#user_phone3'], phoneValidates, '電話番号'],
        },
        'user[phone2]': {
          required: [true, '電話番号'],
          regex_each: [['#user_phone1', '#user_phone2', '#user_phone3'], phoneValidates, '電話番号'],
        },
        'user[phone3]': {
          required: [true, '電話番号'],
          regex_each: [['#user_phone1', '#user_phone2', '#user_phone3'], phoneValidates, '電話番号'],
        },
        'user[email]': {
          required: [true, 'メールアドレス'],
          email: [true, 'メールアドレス'],
        },
        'user[email_confirmation]': {
          required: [true, 'メールアドレス(確認)'],
          equalTo: '#user_email',
        },
        'user[postal_code1]': {
          required: [true, '郵便番号'],
          regex: [/^\d{3}$/, '郵便番号'],
        },
        'user[postal_code2]': {
          required: [true, '郵便番号'],
          regex: [/^\d{4}$/, '郵便番号'],
        },
        'user[prefecture]': {
          required: [true, '都道府県'],
        },
        'user[address1]': {
          required: [true, '市区町村'],
        },
        'user[address2]': {
          required: [true, '町名・番地'],
          grouped_max_length: [['#user_prefecture', '#user_address1', '#user_address2'], 31, '市区町村・町名・番地'],
        },
        'user[address3]': {
          maxlength: 21,
        },
        'user[customer_number]': {
          regex: [/^[0-9a-zA-Z]{6}$/, 'お得意様No'],
        },
        'user[password]': {
          required: [true, 'パスワード'],
          regex: [/^\S{4,}$/, 'パスワード'],
        },
        'user[password_confirmation]': {
          required: [true, 'パスワード(確認)'],
          equalTo: '#user_password',
        },
        'user[magazine]': {
          required: [true, 'メールマガジン受信設定'],
        },
        'order[payment]': {
          required: [true, 'お支払い方法'],
        },
        card_number: {
          required: [true, 'カード番号'],
          creditcard: true,
        },
        card_expire_m: {
          required: [true, '有効期限'],
          regex_each: [['#card_expire_m', '#card_expire_y'], [/^(?:0[1-9]|1[012])$/, /^\d{2}$/], '有効期限'],
        },
        card_expire_y: {
          required: [true, '有効期限'],
          regex_each: [['#card_expire_m', '#card_expire_y'], [/^(?:0[1-9]|1[012])$/, /^\d{2}$/], '有効期限'],
        },
        security_code: {
          required: [true, 'セキュリティコード'],
          regex: [/^\d{3,4}$/, 'セキュリティコード'],
        },
        card_holder: {
          required: [true, 'カード名義'],
          regex: [/^[ -~]+$/, 'カード名義'],
        },
        'destination[family_name]': {
          required: [true, 'お名前(姓)'],
        },
        'destination[first_name]': {
          required: [true, 'お名前(名)'],
        },
        'destination[family_kana]': {
          required: [true, 'フリガナ(セイ)'],
          regex: [reKana, 'フリガナ(セイ)'],
        },
        'destination[first_kana]': {
          required: [true, 'フリガナ(メイ)'],
          regex: [reKana, 'フリガナ(メイ)'],
        },
        'destination[phone1]': {
          required: [true, '電話番号'],
          regex_each: [
            ['#destination_phone1', '#destination_phone2', '#destination_phone3'],
            phoneValidates,
            '電話番号',
          ],
        },
        'destination[phone2]': {
          required: [true, '電話番号'],
          regex_each: [
            ['#destination_phone1', '#destination_phone2', '#destination_phone3'],
            phoneValidates,
            '電話番号',
          ],
        },
        'destination[phone3]': {
          required: [true, '電話番号'],
          regex_each: [
            ['#destination_phone1', '#destination_phone2', '#destination_phone3'],
            phoneValidates,
            '電話番号',
          ],
        },
        'destination[postal_code1]': {
          required: [true, '郵便番号'],
          regex: [/^\d{3}$/, '郵便番号'],
        },
        'destination[postal_code2]': {
          required: [true, '郵便番号'],
          regex: [/^\d{4}$/, '郵便番号'],
        },
        'destination[prefecture]': {
          required: [true, '都道府県'],
        },
        'destination[address1]': {
          required: [true, '市区町村'],
        },
        'destination[address2]': {
          required: [true, '町名・番地'],
          grouped_max_length: [
            ['#destination_prefecture', '#destination_address1', '#destination_address2'],
            31,
            '市区町村・町名・番地',
          ],
        },
        'destination[address3]': {
          maxlength: 21,
        },
      };

    $('select[name^=quantity]').each(function () {
      rules[$(this).attr('name')] = { require_from_group: [1, '.item-quantities'] };
    });

    $.validator.addMethod('regex', function (value, element, params) {
      return this.optional(element) || params[0].test(value);
    });
    $.validator.addMethod('grouped_max_length', function (value, element, params) {
      var groupedIds = params[0],
        maxLength = params[1],
        length = 0;
      $.each(groupedIds, function () {
        if (!$(this).val()) {
          return true;
        }
        length += h2z_dakuten($(this).val()).length;
      });

      return length <= maxLength;
    });
    $.validator.addMethod('regex_each', function (value, element, params) {
      var t = this,
        Ids = params[0],
        regexes = params[1],
        valid = true;
      $.each(Ids, function (k, v) {
        if (!$(v).val()) {
          return true;
        }
        valid = regexes[k].test($(v).val());
        return valid;
      });
      return valid;
    });

    $.extend($.validator.messages, {
      required: '{1}を入力してください。',
      regex: '{1}を正しく入力してください。',
      grouped_max_length: '{2}は{1}文字以内で入力してください。',
      require_from_group: '数量を選択してください',
      regex_each: '{2}を正しく入力してください',
    });

    validator = $('form.order-form').validate({
      onsubmit: false,
      errorClass: 'error-label error-message',
      errorPlacement: function (error, element) {
        error.appendTo(element.closest('td,p'));
      },
      groups: {
        user_phone: 'user[phone1] user[phone2] user[phone3]',
        user_postal_code: 'user[postal_code1] user[postal_code2]',
        destination_phone: 'destination[phone1] destination[phone2] destination[phone3]',
        destination_postal_code: 'destination[postal_code1] destination[postal_code2]',
        card_expire: 'card_expire_m card_expire_y',
      },
      messages: {
        'user[prefecture]': {
          required: '都道府県を選択してください。',
        },
        'user[address3]': {
          maxlength: 'ビル・マンション名は21文字以内で入力してください。',
        },
        'user[password_confirmation]': {
          equalTo: 'パスワード(確認用)が一致しません。',
        },
        'destination[prefecture]': {
          required: '都道府県を選択してください。',
        },
        'destination[address3]': {
          maxlength: 'ビル・マンション名は21文字以内で入力してください。',
        },
      },
      rules: rules,
      showErrors: function (errorMap, errorList) {
        this.defaultShowErrors();
        addErrorClass();
      },
      success: function (label) {
        label.closest('tr,li').removeClass('has-errors');
      },
    });

    // search address
    $('[data-ajaxzip3-target]')
      .each(function () {
        var s = document.getElementById('ajaxzip3-js');
        if (!s) {
          var ss = document.getElementsByTagName('script')[0];
          s = document.createElement('script');
          s.id = 'ajaxzip3-js';
          s.src = 'https://ajaxzip3.github.io/ajaxzip3.js';
          ss.parentElement.insertBefore(s, ss);
        }
      })
      .click(function () {
        var t = $(this).data('ajaxzip3-target');

        // validate address
        AjaxZip3.onSuccess = function () {
          $('select[name="' + t + '[prefecture]"]').change();
          $('input[name="' + t + '[address1]"]').valid();
          $('input[name="' + t + '[address2]"]').valid();
        };
        AjaxZip3.onFailure = function () {
          var error = {};
          error[t + '[postal_code1]'] = '郵便番号を確認してください。';
          validator.showErrors(error);
        };
        AjaxZip3.zip2addr(
          t + '[postal_code1]',
          t + '[postal_code2]',
          t + '[prefecture]',
          t + '[address1]',
          t + '[address2]',
        );
      });

    // セレクトボックスは一回送信しないとvalidationが働かないので
    form.find('select').change(function () {
      $(this).valid();
    });

    // submit
    form.submit(function () {
      var err, s;
      if (!form.valid()) {
        err = $('.error-label:visible:not(:empty)').first();
        s = err.closest('section');
        $('html,body').animate({ scrollTop: (s.offset() || err.offset()).top - $('header').height() });
        return false;
      }
      if (form.data('hasToken') || form.find('[autocomplete="cc-number"]:enabled').length == 0) {
        return;
      }
      getCreditToken(form);
      return false;
    });

    function getCreditToken(form) {
      var data = {
        card_number: form.find('[autocomplete="cc-number"]:enabled').val(),
        card_expire:
          form.find('[autocomplete="cc-exp-month"]:enabled').val() +
          '/' +
          form.find('[autocomplete="cc-exp-year"]:enabled').val(),
        security_code: form.find('[autocomplete="cc-csc"]:enabled').val(),
        cardholder_name: form.find('[autocomplete="cc-name"]:enabled').val(),
        lang: 'ja',
      };

      $.ajax('/token_api_key.json', { method: 'POST' })
        .then(function (json) {
          $.extend(data, json);
          return $.ajax('https://api.veritrans.co.jp/4gtoken', {
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(data),
          });
        })
        .then(function (json) {
          $.each(json, function (k, v) {
            $('<input>')
              .attr('type', 'hidden')
              .attr('name', 'payment[' + k + ']')
              .val(v)
              .appendTo(form);
          });
          $('.payment-details').prop('disabled', true);
          form.data('hasToken', true).submit();
        })
        .fail(function () {
          validator.showErrors({
            card_number: 'カード情報の入力に誤りがあります。もう一度ご確認の上、送信してください。',
          });
          $('#card_number').focus();
        });
    }
  });

  $('#destination_user').each(function () {
    var check = $(this),
      elements = $('#order-form-destination :input'),
      val,
      attributes;
    function copydata() {
      if (check.prop('checked')) {
        elements.each(function () {
          var id = this.id;
          if (id) {
            $('#' + id)
              .parents('.has-errors')
              .removeClass('has-errors')
              .find('label.error-message')
              .remove();
            $(this).val($('#' + id.replace(/^destination_/, 'user_')).val());
          }
        });
      }
    }
    check
      .on('change.destination', function () {
        $('#choose-destination').prop('disabled', this.checked);
        elements.prop('disabled', this.checked);
        copydata();
      })
      .trigger('change.destination');
    $('#order-form-user :input').blur(function () {
      copydata();
    });
    return false;
  });
  $('#choose-destination').click(function () {
    $('#destinations-popup')
      .dialog({
        modal: true,
        autoOpen: false,
        title: 'お届け先を選択する',
        buttons: {
          閉じる: function () {
            $(this).dialog('close');
          },
        },
        width: '950px',
        maxWidth: '95vw',
      })
      .dialog('open');
  });
  $('#destinations-popup').on('click', '.copy-destination', function () {
    var destination = $(this).data('destination');
    storePopup('#order-form-destination', destination, 'destination');
    $('#destinations-popup').dialog('close');
  });
  $('#choose-user-destination').click(function () {
    $('#user-destinations-popup')
      .dialog({
        modal: true,
        autoOpen: false,
        title: 'ご依頼主様とお届け先を選択する',
        buttons: {
          閉じる: function () {
            $(this).dialog('close');
          },
        },
        width: '950px',
        maxWidth: '95vw',
      })
      .dialog('open');
  });
  $('#user-destinations-popup').on('click', '.copy-destination', function () {
    var user = $(this).data('user'),
      destination = $(this).data('destination');
    storePopup('#order-form-user', user, 'user');
    storePopup('#order-form-destination', destination, 'destination');
    $('#user-destinations-popup').dialog('close');
  });
  function storePopup(cntr, source, prefix) {
    var re = new RegExp('^' + prefix + '_');
    $(cntr)
      .find(':input')
      .each(function () {
        var id = this.id;
        if (id) {
          $('#' + id)
            .parents('.has-errors')
            .removeClass('has-errors')
            .find('label.error-message')
            .remove();
          $(this).val(source[id.replace(re, '')]);
        }
      });
  }
  $('.destinations-popup').on('input compositionend', '.destinations-selections-filter', function (e) {
    if (e.originalEvent.isComposing) return;
    var input = $(e.target),
      value = searchNorm(input.prop('value'));
    input
      .closest('.destinations-popup')
      .find('.copy-destination')
      .each(function () {
        var li = $(this);
        li.toggle(value === '' || searchDest(value, li.data('destination')) || searchDest(value, li.data('user')));
      });
  });
  function searchNorm(str) {
    return ('' + (str || ''))
      .normalize('NFKC')
      .trim()
      .replace(/[\u3041-\u3096]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) + 0x60);
      });
  }
  function searchDest(value, data) {
    var result = false;
    $.each(data, function () {
      if (this != null && searchNorm(this).includes(value)) {
        result = true;
        return false;
      }
    });
    return result;
  }

  // dialog
  $('[data-dialog-popup]').click(function () {
    $($(this).attr('href')).dialog({
      autoOpen: true,
      modal: true,
      width: 'auto',
      resizable: false,
      dialogClass: 'centerDialog',
      buttons: {
        閉じる: function () {
          $(this).dialog('close');
        },
      },
      close: function () {
        $(this).dialog('destroy');
      },
    });
    return false;
  });

  // toggle payment detail
  $('input[name="order[payment]"]')
    .change(function () {
      var label = $(this).closest('label'),
        details = label.find('.payment-details').prop('disabled', false).slideDown(100);
      $('.payment-details:visible').not(details).prop('disabled', true).slideUp(100);
    })
    .filter(':checked')
    .change();
  $('.payment-previous').change(function () {
    $(this).closest('fieldset').find('[autocomplete^="cc-"]').prop('disabled', this.checked);
  });
});
